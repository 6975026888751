import { useQuery } from '@apollo/react-hooks';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { markErrorAsHandled, isErrorMarkedAsHandled } from '@confluence/graphql';
import { ConfluenceEdition } from '@confluence/change-edition';

import { RediscoverReactionsTrialDetailsQuery } from '../graphql/RediscoverReactionsTrialDetailsQuery.graphql';
import type { RediscoverReactionsTrialDetailsQuery as RediscoverReactionsTrialDetailsQueryType } from '../graphql/__types__/RediscoverReactionsTrialDetailsQuery';

const REDISCOVER_REACTIONS_FLAG = 'cc-notifications-rediscover-reactions';
const REDISCOVER_REACTIONS_COHORT_KEY = 'cohort';
enum REDISCOVER_REACTIONS_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	BYLINE = 'byline',
	FLOATING = 'floating',
}

type useRediscoverReactionsExperimentProps = {
	skip?: boolean;
};

export const useRediscoverReactionsExperiment = ({
	skip = false,
}: useRediscoverReactionsExperimentProps = {}) => {
	const { edition, isLoggedIn, accessStatus } = useSessionData();

	const cohort = FeatureGates.getExperimentValue<REDISCOVER_REACTIONS_COHORTS>(
		REDISCOVER_REACTIONS_FLAG,
		REDISCOVER_REACTIONS_COHORT_KEY,
		REDISCOVER_REACTIONS_COHORTS.NOT_ENROLLED,
		{
			fireExperimentExposure: false,
		},
	);

	const isLicensed = [
		AccessStatus.LICENSED_ADMIN_ACCESS,
		AccessStatus.LICENSED_USE_ACCESS,
	].includes(accessStatus as AccessStatus);

	const isEnrolled = [
		REDISCOVER_REACTIONS_COHORTS.CONTROL,
		REDISCOVER_REACTIONS_COHORTS.BYLINE,
		REDISCOVER_REACTIONS_COHORTS.FLOATING,
	].includes(cohort);

	const isStandardOrPremium = [ConfluenceEdition.STANDARD, ConfluenceEdition.PREMIUM].includes(
		edition as ConfluenceEdition,
	);

	const isEligible = !skip && isLoggedIn && isLicensed && isEnrolled && isStandardOrPremium;

	const {
		data: trialDetailsData,
		error: trialDetailsError,
		loading: trialDetailsLoading,
	} = useQuery<RediscoverReactionsTrialDetailsQueryType>(RediscoverReactionsTrialDetailsQuery, {
		skip: !isEligible,
	});

	if (trialDetailsError && !isErrorMarkedAsHandled(trialDetailsError)) {
		markErrorAsHandled(trialDetailsError);
	}

	let isTrialInstance = true;
	if (!trialDetailsLoading && !trialDetailsError && !trialDetailsData?.license?.trialEndDate) {
		isTrialInstance = false;
	}

	if (isEligible) {
		if (!isTrialInstance) {
			FeatureGates.manuallyLogExperimentExposure(REDISCOVER_REACTIONS_FLAG);
		}
		return {
			isRediscoverReactionsEligible: true,
			isControlCohort: cohort === REDISCOVER_REACTIONS_COHORTS.CONTROL,
			isBylineCohort: cohort === REDISCOVER_REACTIONS_COHORTS.BYLINE,
			isFloatingCohort: cohort === REDISCOVER_REACTIONS_COHORTS.FLOATING,
		};
	} else {
		return {
			isRediscoverReactionsEligible: false,
		};
	}
};
