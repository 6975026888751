import type { FC, ComponentProps } from 'react';
import React from 'react';

import { ByLine, ContentTitle } from '@confluence/content-header';

import { useViewPageParams } from './useViewPageParams';

type ByLinePassThroughProps = Pick<
	ComponentProps<typeof ByLine>,
	'hasByLineContributors' | 'hasByLineExtensions'
>;

type Props = Partial<ByLinePassThroughProps> &
	ComponentProps<typeof ContentTitle> & {
		hasCoverPicture?: boolean;
		pageWidthType?: string;
		isEmbeddedPage?: boolean;
		children?: React.ReactNode;
	};

export const ContentTitleWithByLine: FC<Props> = ({
	contentId,
	spaceKey,
	banner,
	hasByLineContributors,
	hasByLineExtensions,
	headingContainer,
	hasCoverPicture,
	pageWidthType,
	isEmbeddedPage,
	children,
}: Props) => {
	const { versionOverride, embeddedContentRender } = useViewPageParams();

	const renderContentTitleWithByLine = () => {
		return (
			<ContentTitle
				banner={banner}
				contentId={contentId}
				spaceKey={spaceKey}
				headingContainer={headingContainer}
				hasCoverPicture={hasCoverPicture}
				pageWidthType={pageWidthType}
				isEmbeddedPage={isEmbeddedPage}
			>
				{children}
				<ByLine
					contentId={contentId}
					versionOverride={versionOverride}
					embeddedContentRender={embeddedContentRender}
					hasByLineContributors={hasByLineContributors}
					hasByLineExtensions={hasByLineExtensions}
				/>
			</ContentTitle>
		);
	};

	return renderContentTitleWithByLine();
};
